import React from "react"
import { Link } from "gatsby"
import Button from "react-bootstrap/Button"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Heading from "../components/Layout/Heading"
import SubHeading from "../components/Layout/SubHeading"
import IncomingCars from "../components/IncomingCars"
import Spacer from "../components/Layout/Spacer"
import Icon from "../components/Icon"
import StockList from "../components/StockList"

const MidCityStock = ({ location, pageContext }) => {
  const { stock, incomingCars } = pageContext
  const dealership = "midcity"

  return (
    <Layout location={location}>
      <SEO
        title="Used Vehicles | Solomons Mid City Autos"
        description="Our selection of quality used vehicles located on the Princes Highway in Nowra"
      />
      <Heading title="Used Vehicles" />
      <SubHeading subHeading="Solomons Mid City Autos" />
      <IncomingCars dealership={dealership} incomingCars={incomingCars} />
      <Spacer />
      <StockList dealership={dealership} stockVehicles={stock} />
      <div className="row more-stock-container">
        <div className="col-12 text-center">
          <p>We have more cars available at Solomon Motors City</p>
          <Link to="/used-vehicles/solomon-motors-city">
            <Button variant="primary" bsPrefix="smg">
              View Stock <Icon prefix="fas" name="arrow-circle-right" />
            </Button>
          </Link>
        </div>
      </div>
    </Layout>
  )
}

export default MidCityStock
